// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body {
  margin: 0; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex; 
  justify-content: center; 
  /* height: 80vh;  */

  height:100vh;
  width: 100vw;
  align-items: center;  
  text-align: center; 
}
/* display: flex; 启用Flex布局 
  justify-content: center; 水平居中 
  align-items: center; 垂直居中 
  height: 100vh; 设置body高度为视口高度 
  text-align: center; 文本居中（对内部元素有效） */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;EACE,SAAS;EACT;8CAC4C;EAC5C,mCAAmC;EACnC,kCAAkC;EAClC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;EAEnB,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;AACpB;AACA;;;;qCAIqC;;AAErC;EACE,+EAA+E;AACjF","sourcesContent":["\nbody {\n  margin: 0; \n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',\n    'Droid Sans', 'Helvetica Neue', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  display: flex; \n  justify-content: center; \n  /* height: 80vh;  */\n\n  height:100vh;\n  width: 100vw;\n  align-items: center;  \n  text-align: center; \n}\n/* display: flex; 启用Flex布局 \n  justify-content: center; 水平居中 \n  align-items: center; 垂直居中 \n  height: 100vh; 设置body高度为视口高度 \n  text-align: center; 文本居中（对内部元素有效） */\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

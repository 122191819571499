import { Buffer } from 'buffer';
import axios from 'axios';
import process from 'process';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { ThemeProvider } from './contexts/ColorThemeContext';
import { WalletLockProvider } from './contexts/WalletLockContext';
import { Web3Provider } from './contexts/Web3Context';
import './index.css';
global.Buffer = Buffer;
global.process = process;
window.Buffer = Buffer;
axios.defaults.timeout = 20000;


axios.interceptors.response.use(//这里是axios的拦截器，用来处理请求和响应的错误
  response => response,
  error => {
    console.error("API call failed:", error.config ? error.config.url : 'unknown URL');
    console.error("Error message:", error.message);
    console.error("Error stack:", error.stack);
    //alert("Network error, please try again later.");
    return Promise.reject(error);
  }
);


declare global {
  interface Window {
    TelegramGameProxy: { receiveEvent(): void };
  }
}
window.TelegramGameProxy = { receiveEvent() {} };//这里是对tg移动端的优化 ios

const root = document.getElementById('root')!;
const rootDiv = ReactDOM.createRoot(root);
rootDiv.render(
  <ThemeProvider>
    <WalletLockProvider>
      <Web3Provider>
        <App />
      </Web3Provider>
    </WalletLockProvider>
  </ThemeProvider>,
);


//这里是代码交易在本地钱包的工作流程
//这里是与发送币相关的代码
import init, {
  BSM,
  ChainParams,
  P2PKHAddress,
  PrivateKey,
  PublicKey,
  Script,
  SigHash,
  Signature,
  Transaction,
  TxIn,
  TxOut,
} from 'bsv-wasm-web';
// import { buildInscription } from 'js-1sat-ord-web';
import { useEffect, useState } from 'react';
import { SignMessageResponse } from '../pages/requests/SignMessageRequest';
import {
  TBC_DECIMAL_CONVERSION,
  FEE_PER_BYTE,
  MAX_BYTES_PER_TX,
  MAX_FEE_PER_TX,
  P2PKH_INPUT_SIZE,
  P2PKH_OUTPUT_SIZE,
  DUST,
} from '../utils/constants';
import { removeBase64Prefix } from '../utils/format';
import { DerivationTag, getPrivateKeyFromTag, Keys } from '../utils/keys';
import { NetWork } from '../utils/network';
import { storage } from '../utils/storage';
import { useTBChainPyPool } from './useTBChainPyPool';
import { useKeys } from './useKeys';
import { useNetwork } from './useNetwork';
import { useTuringBitChain } from './useTuringBitChain';
export interface UTXO {
  satoshis: number;
  vout: number;
  txid: string;
  script: string;
}

export interface StoredUtxo extends UTXO {
  spent: boolean;
  spentUnixTime: number;
}

  type SendTbcResponse = {
    txid?: string;
    rawtx?: string;
    amount?: number;
    change?: number;
    fee?: number;
    error?: string;
  };

type FundRawTxResponse = { rawtx?: string; error?: string };

export type MimeTypes =
  | 'text/plain'
  | 'text/html'
  | 'text/css'
  | 'application/javascript'
  | 'application/json'
  | 'application/xml'
  | 'image/jpeg'
  | 'image/png'
  | 'image/gif'
  | 'image/svg+xml'
  | 'audio/mpeg'
  | 'audio/wav'
  | 'audio/wave'
  | 'video/mp4'
  | 'application/pdf'
  | 'application/msword'
  | 'application/vnd.ms-excel'
  | 'application/vnd.ms-powerpoint'
  | 'application/zip'
  | 'application/x-7z-compressed'
  | 'application/x-gzip'
  | 'application/x-tar'
  | 'application/x-bzip2';

export type MAP = { app: string; type: string; [prop: string]: string };

export type RawInscription = {
  base64Data: string;
  mimeType: MimeTypes;
  map?: MAP;
};

export type Web3SendTbcRequest = {
  satoshis: number;
  address?: string;
  data?: string[]; // hex string array
  script?: string; // hex string
  inscription?: RawInscription;
}[];

export type Web3BroadcastRequest = {
  rawtx: string;
  fund?: boolean;
};

export type Web3SignMessageRequest = {
  message: string;
  encoding?: 'utf8' | 'hex' | 'base64';
  tag?: DerivationTag;
};

export type Web3EncryptRequest = {
  message: string;
  pubKeys: string[];
  encoding?: 'utf8' | 'hex' | 'base64';
  tag?: DerivationTag;
};

export type Web3DecryptRequest = {
  messages: string[];
  tag?: DerivationTag;
};

export const useTbc = () => {
  const [tbcBalance, setTbcBalance] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const { retrieveKeys, tbcAddress, verifyPassword, tbcPubKey, identityAddress, identityPubKey } = useKeys();
  const { network } = useNetwork();
  const { broadcastWithTBChainPyPool, getTxOut } = useTBChainPyPool();
  const { getUtxos, getTbcBalance, getExchangeRate, getInputs } = useTuringBitChain();

  const getChainParams = (network: NetWork): ChainParams => {
    return network === NetWork.Mainnet ? ChainParams.mainnet() : ChainParams.testnet();
  };

  useEffect(() => {
    if (!tbcAddress) return;
    getUtxos(tbcAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tbcAddress]);

  const assembleTbc = async (
    request: Web3SendTbcRequest,
    password: string,
    noApprovalLimit?: number,
  ): Promise<SendTbcResponse> => {
    try {
      setIsProcessing(true);
      await init();
      const requestSats = request.reduce((a: number, item: { satoshis: number }) => a + item.satoshis, 0);
      const tbcSendAmount = requestSats / TBC_DECIMAL_CONVERSION;

      if (tbcSendAmount > Number(noApprovalLimit)) {
        const isAuthenticated = await verifyPassword(password);
        if (!isAuthenticated) {
          return { error: 'invalid-password' };
        }
      }

      let feeSats = 80;//这是每笔的最少手续费
      const isBelowNoApprovalLimit = Number(tbcSendAmount) <= Number(noApprovalLimit);
      const keys = await retrieveKeys(password, isBelowNoApprovalLimit);
      if (!keys?.walletWif || !keys.walletPubKey) throw Error('Undefined key');
      const paymentPk = PrivateKey.from_wif(keys.walletWif);
      const pubKey = paymentPk.to_public_key();
      const fromAddress = pubKey.to_address().set_chain_params(getChainParams(network)).to_string();
      const amount = request.reduce((a, r) => a + r.satoshis, 0);

      // Format in and outs
      const fundingUtxos = await getUtxos(fromAddress);

      if (!fundingUtxos) throw Error('No Utxos!');
      const totalSats = fundingUtxos.reduce((a: number, item: UTXO) => a + item.satoshis, 0);

      if (totalSats < amount ) {
        return { error: 'insufficient-funds' };
      }

      const sendAll = totalSats === amount;
      const satsOut = sendAll ? totalSats - feeSats : amount;
      const inputs = getInputs(fundingUtxos, satsOut+feeSats, sendAll);

      if ('error' in inputs) {
        return { error: inputs.error };
    }

      const totalInputSats = inputs.reduce((a, item) => a + item.satoshis, 0);

      const tx = new Transaction(10, 0)

      request.forEach((req) => {
        let outScript: Script;
        if (req.address) {
          if (req.inscription) {
            const { base64Data, mimeType, map } = req.inscription;
            const formattedBase64 = removeBase64Prefix(base64Data);
            outScript = P2PKHAddress.from_string(req.address).get_locking_script();
            //feeSats += Math.ceil(outScript.to_bytes().byteLength * FEE_PER_BYTE);
          } else {
            outScript = P2PKHAddress.from_string(req.address).get_locking_script();
            //feeSats += Math.ceil(outScript.to_bytes().byteLength * FEE_PER_BYTE);
          }
        } else if (req.script) {
          outScript = Script.from_hex(req.script);
          //feeSats += Math.ceil(outScript.to_bytes().byteLength * FEE_PER_BYTE);
          // const reqBytes = JSON.stringify(req).length; // 这里是修改fee的逻辑
          // feeSats = Math.max( feeSats,Math.ceil(reqBytes * FEE_PER_BYTE));
        } else if ((req.data || []).length > 0) {
          let asm = `OP_0 OP_RETURN ${req.data?.join(' ')}`;
          try {
            outScript = Script.from_asm_string(asm);
          } catch (e) {
            throw Error('Invalid data');
          }
        } else {
          throw Error('Invalid request');
        }

        // TODO: In event where provider method calls this and happens to have multiple outputs that equal all sats available in users wallet, this tx will likely fail due to no fee to miner. Considering an edge case for now.
        const outSats = sendAll && request.length === 1 ? satsOut : req.satoshis;
        if (outSats < DUST) {
          return { error: "output is below DUST" };
        }
        tx.add_output(new TxOut(BigInt(outSats), outScript));
      });

      //feeSats = Math.max( feeSats,tx.to_bytes().byteLength);

      const estimatedOutputSize = tx.to_bytes().byteLength+P2PKH_OUTPUT_SIZE; // 估算输出的大小
      const estimatedInputSize = inputs.length * P2PKH_INPUT_SIZE; // 估算每个输入的大小
      const estimatedTotalSize = estimatedOutputSize + estimatedInputSize;
      feeSats = Math.max(feeSats,  Math.ceil(estimatedTotalSize*FEE_PER_BYTE+1));

      let change =0;
      if (!sendAll &&totalInputSats - satsOut - feeSats>= DUST) {//这里是灰尘交易判断
        change =totalInputSats - satsOut - feeSats;
        tx.add_output(new TxOut(BigInt(change), P2PKHAddress.from_string(fromAddress).get_locking_script()));
      }
      // build txins from our inputs
      let idx = 0;
      for (let u of inputs || []) {
        const inTx = new TxIn(Buffer.from(u.txid, 'hex'), u.vout, Script.from_hex(''));

        inTx.set_satoshis(BigInt(u.satoshis));
        tx.add_input(inTx);

        const sig = tx.sign(paymentPk, SigHash.InputOutputs, idx, Script.from_hex(u.script), BigInt(u.satoshis));//签名

        inTx.set_unlocking_script(Script.from_asm_string(`${sig.to_hex()} ${paymentPk.to_public_key().to_hex()}`));
        tx.set_input(idx, inTx);
        idx++;
}
      // Fee checker
      const finalSatsIn = tx.satoshis_in() ?? 0n;
      const finalSatsOut = tx.satoshis_out() ?? 0n;
      if (finalSatsIn - finalSatsOut > MAX_FEE_PER_TX) return { error: 'fee-too-high' };

      // Size checker
      const bytes = tx.to_bytes().byteLength;
      if (bytes > MAX_BYTES_PER_TX) return { error: 'tx-size-too-large' };

      const rawtx = tx.to_hex();

      return { rawtx:rawtx,
        amount: satsOut,
        change:change,
        fee: feeSats,};
    } catch (error: any) {
      console.log(error);
      return { error: error.message ?? 'unknown' };
    }
  };

  const sendtbc = async (
    rawtx: string,
  ): Promise<{ txid?: string; error?: string }> => {
    try {
      // const isAuthenticated = await verifyPassword(password);
      // if (!isAuthenticated) {
      //   return { error: 'invalid-password' };
      // }
      //console.log(rawtx);
      const { txid } = await broadcastWithTBChainPyPool(rawtx);
      //console.log('txid:', txid);

      return { txid };
    } catch (error: any) {
      console.log(error);
      return { error: error.message ?? 'unknown' };
    }finally {
      setIsProcessing(false);
    }
  };

  const sendTbc = async (
    request: Web3SendTbcRequest,
    password: string,
    noApprovalLimit?: number,
  ): Promise<SendTbcResponse> => {
    try {
      setIsProcessing(true);
      await init();
      const requestSats = request.reduce((a: number, item: { satoshis: number }) => a + item.satoshis, 0);
      const tbcSendAmount = requestSats / TBC_DECIMAL_CONVERSION;

      if (tbcSendAmount > Number(noApprovalLimit)) {
        const isAuthenticated = await verifyPassword(password);
        if (!isAuthenticated) {
          return { error: 'invalid-password' };
        }
      }

      let feeSats = 80;//这是每笔的最少手续费
      const isBelowNoApprovalLimit = Number(tbcSendAmount) <= Number(noApprovalLimit);
      const keys = await retrieveKeys(password, isBelowNoApprovalLimit);
      if (!keys?.walletWif || !keys.walletPubKey) throw Error('Undefined key');
      const paymentPk = PrivateKey.from_wif(keys.walletWif);
      const pubKey = paymentPk.to_public_key();
      const fromAddress = pubKey.to_address().set_chain_params(getChainParams(network)).to_string();
      const amount = request.reduce((a, r) => a + r.satoshis, 0);

      // Format in and outs
      const fundingUtxos = await getUtxos(fromAddress);

      if (!fundingUtxos) throw Error('No Utxos!');
      const totalSats = fundingUtxos.reduce((a: number, item: UTXO) => a + item.satoshis, 0);

      if (totalSats < amount ) {
        return { error: 'insufficient-funds' };
      }

      const sendAll = totalSats === amount;
      const satsOut = sendAll ? totalSats - feeSats : amount;
      const inputs = getInputs(fundingUtxos, satsOut+feeSats, sendAll);

      if ('error' in inputs) {
        return { error: inputs.error };
    }

      const totalInputSats = inputs.reduce((a, item) => a + item.satoshis, 0);

      const tx = new Transaction(10, 0)

      request.forEach((req) => {
        let outScript: Script;
        if (req.address) {
          if (req.inscription) {
            const { base64Data, mimeType, map } = req.inscription;
            const formattedBase64 = removeBase64Prefix(base64Data);
            outScript = Script.from_asm_string("string");
            //feeSats += Math.ceil(outScript.to_bytes().byteLength * FEE_PER_BYTE);
          } else {
            outScript = P2PKHAddress.from_string(req.address).get_locking_script();
            //feeSats += Math.ceil(outScript.to_bytes().byteLength * FEE_PER_BYTE);
          }
        } else if (req.script) {
          outScript = Script.from_hex(req.script);
          //feeSats += Math.ceil(outScript.to_bytes().byteLength * FEE_PER_BYTE);
          // const reqBytes = JSON.stringify(req).length; // 这里是修改fee的逻辑
          // feeSats = Math.max( feeSats,Math.ceil(reqBytes * FEE_PER_BYTE));
        } else if ((req.data || []).length > 0) {
          let asm = `OP_0 OP_RETURN ${req.data?.join(' ')}`;
          try {
            outScript = Script.from_asm_string(asm);
          } catch (e) {
            throw Error('Invalid data');
          }
        } else {
          throw Error('Invalid request');
        }

        // TODO: In event where provider method calls this and happens to have multiple outputs that equal all sats available in users wallet, this tx will likely fail due to no fee to miner. Considering an edge case for now.
        const outSats = sendAll && request.length === 1 ? satsOut : req.satoshis;
        if (outSats < DUST) {
          return { error: "output is below DUST" };
        }
        tx.add_output(new TxOut(BigInt(outSats), outScript));
      });

      //feeSats = Math.max( feeSats,tx.to_bytes().byteLength);

      const estimatedOutputSize = tx.to_bytes().byteLength+P2PKH_OUTPUT_SIZE; // 估算输出的大小
      const estimatedInputSize = inputs.length * P2PKH_INPUT_SIZE; // 估算每个输入的大小
      const estimatedTotalSize = estimatedOutputSize + estimatedInputSize;
      feeSats = Math.max(feeSats,  estimatedTotalSize * FEE_PER_BYTE);

      let change =0;
      if (!sendAll &&totalInputSats - satsOut - feeSats>= DUST) {//这里是灰尘交易判断
        change =totalInputSats - satsOut - feeSats;
        tx.add_output(new TxOut(BigInt(change), P2PKHAddress.from_string(fromAddress).get_locking_script()));
      }
      // build txins from our inputs
      let idx = 0;
      for (let u of inputs || []) {
        const inTx = new TxIn(Buffer.from(u.txid, 'hex'), u.vout, Script.from_hex(''));

        inTx.set_satoshis(BigInt(u.satoshis));
        tx.add_input(inTx);

        const sig = tx.sign(paymentPk, SigHash.InputOutputs, idx, Script.from_hex(u.script), BigInt(u.satoshis));//签名

        inTx.set_unlocking_script(Script.from_asm_string(`${sig.to_hex()} ${paymentPk.to_public_key().to_hex()}`));
        tx.set_input(idx, inTx);
        idx++;
}
      // Fee checker
      const finalSatsIn = tx.satoshis_in() ?? 0n;
      const finalSatsOut = tx.satoshis_out() ?? 0n;
      if (finalSatsIn - finalSatsOut > MAX_FEE_PER_TX) return { error: 'fee-too-high' };

      // Size checker
      const bytes = tx.to_bytes().byteLength;
      if (bytes > MAX_BYTES_PER_TX) return { error: 'tx-size-too-large' };

      const rawtx = tx.to_hex();
      let { txid } = await broadcastWithTBChainPyPool(rawtx);//这里是调用广播交易的api
    // console.log('txid:', txid);
      if (txid) {
        if (isBelowNoApprovalLimit) {
          storage.get(['noApprovalLimit'], ({ noApprovalLimit }) => {
            storage.set({
              noApprovalLimit: noApprovalLimit
                ? Number((noApprovalLimit - amount / TBC_DECIMAL_CONVERSION).toFixed(8))
                : 0,
            });
          });
        }
      }
      return { txid};
    } catch (error: any) {
      console.log(error);
      return { error: error.message ?? 'unknown' };
    } finally {
      setIsProcessing(false);
    }
  };

  const signMessage = async (//这里是使用用户私钥来签名
    messageToSign: Web3SignMessageRequest,
    password: string,
  ): Promise<SignMessageResponse | undefined> => {
    const { message, encoding } = messageToSign;
    const isAuthenticated = await verifyPassword(password);//验证给定的消息和签名是否匹配指定的公钥。
    if (!isAuthenticated) {
      return { error: 'invalid-password' };
    }
    try {
      const keys = (await retrieveKeys(password)) as Keys;
      const derivationTag = messageToSign.tag ?? { label: 'panda', id: 'identity', domain: '', meta: {} };
      const privateKey = getPrivateKeyFromTag(derivationTag, keys);

      if (!privateKey.to_wif()) {
        return { error: 'key-type' };
      }

      const publicKey = privateKey.to_public_key();
      const address = publicKey.to_address().set_chain_params(getChainParams(network)).to_string();

      const msgBuf = Buffer.from(message, encoding);
      const signature = BSM.sign_message(privateKey, msgBuf);
      return {
        address,
        pubKey: publicKey.to_hex(),
        message: message,
        sig: Buffer.from(signature.to_compact_hex(), 'hex').toString('base64'),
        derivationTag,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const verifyMessage = (
    message: string,
    signatureHex: string,
    publicKeyHex: string,
    encoding: 'utf8' | 'hex' | 'base64' = 'utf8',
  ) => {
    try {
      const msgBuf = Buffer.from(message, encoding);
      const publicKey = PublicKey.from_hex(publicKeyHex);
      const signature = Signature.from_compact_bytes(Buffer.from(signatureHex, 'hex'));
      const address = publicKey.to_address().set_chain_params(getChainParams(network));

      return address.verify_bitcoin_message(msgBuf, signature);
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const updateTbcBalance = async (pullFresh?: boolean) => {//这里是更新Blockchain获取TBC地址的最新余额。
    const total = await getTbcBalance(tbcAddress, pullFresh);
    setTbcBalance(total ?? 0);
    // if(pullFresh===true){console.log("调用了云端余额更新")};
    // if(pullFresh===false){console.log("调用了本地余额更新")};
  };

  const rate = async () => {
    const r = await getExchangeRate();
    setExchangeRate(r ?? 0);
  };

  const fundRawTx = async (rawtx: string, password: string): Promise<FundRawTxResponse> => {
    const isAuthenticated = await verifyPassword(password);
    if (!isAuthenticated) {
      return { error: 'invalid-password' };
    }

    const keys = await retrieveKeys(password);
    if (!keys.walletWif) throw new Error('Missing keys');
    const paymentPk = PrivateKey.from_wif(keys.walletWif);

    let satsIn = 0;
    let satsOut = 0;
    const tx = Transaction.from_hex(rawtx);
    let inputCount = tx.get_ninputs();
    for (let i = 0; i < inputCount; i++) {
      const txIn = tx.get_input(i);
      const txOut = await getTxOut(txIn!.get_prev_tx_id_hex(), txIn!.get_vout());
      satsIn += Number(txOut!.get_satoshis());
    }
    for (let i = 0; i < tx.get_noutputs(); i++) {
      satsOut += Number(tx.get_output(i)!.get_satoshis()!);
    }
    let size = rawtx.length / 2 + P2PKH_OUTPUT_SIZE;
    let fee = Math.ceil(size * FEE_PER_BYTE);
    const fundingUtxos = await getUtxos(tbcAddress);
    while (satsIn < satsOut + fee) {
      const utxo = fundingUtxos.pop();
      if (!utxo) throw Error('Insufficient funds');
      const txIn = new TxIn(Buffer.from(utxo.txid, 'hex'), utxo.vout, Script.from_hex(''));
      tx.add_input(txIn);
      satsIn += Number(utxo.satoshis);
      size += P2PKH_INPUT_SIZE;
      fee = Math.ceil(size * FEE_PER_BYTE);
      const sig = tx.sign(paymentPk, SigHash.Input, inputCount, Script.from_hex(utxo.script), BigInt(utxo.satoshis));
      txIn.set_unlocking_script(Script.from_asm_string(`${sig.to_hex()} ${paymentPk.to_public_key().to_hex()}`));
      tx.set_input(inputCount++, txIn);
    }
    tx.add_output(new TxOut(BigInt(satsIn - satsOut - fee), P2PKHAddress.from_string(tbcAddress).get_locking_script()));
    return { rawtx: tx.to_hex() };
  };

  useEffect(() => {
    if (!tbcAddress) return;
    updateTbcBalance();
    rate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tbcAddress]);

  return {
    tbcBalance,
    tbcAddress,
    tbcPubKey,
    identityAddress,
    identityPubKey,
    isProcessing,
    assembleTbc,
    sendtbc,
    sendTbc,
    setIsProcessing,
    updateTbcBalance,
    exchangeRate,
    signMessage,
    verifyMessage,
    fundRawTx,
    retrieveKeys,
    getChainParams,
  };
};

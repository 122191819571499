import { useEffect, useState} from 'react';
import styled from 'styled-components';
import { WhitelistedApp } from '../App';
import x from '../assets/x.svg';
import axios from 'axios';
import { BackButton } from '../components/BackButton';
import { Button } from '../components/Button';
import { ForwardButton } from '../components/ForwardButton';
import { Input } from '../components/Input';  
import { QrCode } from '../components/QrCode';
import { Text } from '../components/Reusable';
import { SettingsRow } from '../components/SettingsRow';
import { Show } from '../components/Show';
import { SpeedBump } from '../components/SpeedBump';
import { ToggleSwitch } from '../components/ToggleSwitch';
import { TopNav } from '../components/TopNav';
import { useBottomMenu } from '../hooks/useBottomMenu';
import { useKeys } from '../hooks/useKeys';
import { useSnackbar } from '../hooks/useSnackbar';
import { useSocialProfile } from '../hooks/useSocialProfile';
import { useTheme } from '../hooks/useTheme';
import { useWalletLockState } from '../hooks/useWalletLockState';
import { useWeb3Context } from '../hooks/useWeb3Context';
import { ColorThemeProps } from '../theme';
import { SNACKBAR_TIMEOUT } from '../utils/constants';
import { NetWork } from '../utils/network';
import { storage } from '../utils/storage';
import { truncate } from '../utils/format';
import copyIcon from '../assets/copy-green.svg';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(75%);
  overflow-y: auto;
  overflow-x: hidden;
`;

const ConnectedAppRow = styled.div<ColorThemeProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.darkAccent};
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.25rem;
  width: 80%;
`;

const SettingsText = styled(Text)<ColorThemeProps>`
  color: ${({ theme }) => theme.white};
  margin: 0;
  font-weight: 600;
  text-align: left;
`;

const XIcon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
`;

const AppIcon = styled.img`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  border-radius: 0.5rem;
`;

const ImageAndDomain = styled.div`
  display: flex;
  align-items: center;
`;

const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 25rem;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding: 1rem;
`;

const ExportKeysAsQrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
`;

const PageWrapper = styled.div<{ $marginTop: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => props.$marginTop};
  width: 100%;
`;

const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 2rem 0;
`;

// const LockDetailsContainer = styled.div`
//   display: flex;
//   flex-direction: column; // 改变为列布局
//   align-items: stretch; // 对齐到容器的两端
//   width: 100%;
//   margin-bottom: 10px;
  
// `;

// 更新 LockDetailsContainer 样式以支持滚动刷新
const LockDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;
`;

// const TextContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 0.5rem;
//   width: 90%;
//   margin-bottom: 10px;
// `;
const TransactionRow = styled.div`
  display: flex;
  align-items: left;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.darkAccent};
  overflow-y: auto;
  overflow-x: hidden;
`;


const LockDetailsText = styled(Text)<ColorThemeProps>`
  margin: 0;
  color: ${(props) => props.theme.white};
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
`;

const StyledCopy = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
`;


type SettingsPage =
  | 'main'
  | 'connected-apps'
  | 'social-profile'
  | 'export-keys-options'
  | 'export-historic-transactions'
  | 'export-keys-qr'
  | 'export-mnemonic-words'
  | 'preferences'
  | 'export-transaction-details';
type DecisionType = 'sign-out' | 'export-keys' | 'export-keys-qr-code'|'export-mnemonic-words';

export const Settings = () => {
  const { theme } = useTheme();
  const { setSelected } = useBottomMenu();
  const { lockWallet } = useWalletLockState();
  const [showSpeedBump, setShowSpeedBump] = useState(false);
  const { addSnackbar } = useSnackbar();
  const {
    network,
    updateNetwork,
    isPasswordRequired,
    updatePasswordRequirement,
    updateNoApprovalLimit,
    noApprovalLimit,
  } = useWeb3Context();
  const [page, setPage] = useState<SettingsPage>('main');
  const [connectedApps, setConnectedApps] = useState<WhitelistedApp[]>([]);
  const [speedBumpMessage, setSpeedBumpMessage] = useState('');
  const [decisionType, setDecisionType] = useState<DecisionType | undefined>();
  const { retrieveKeys,tbcAddress } = useKeys();
  const { socialProfile, storeSocialProfile } = useSocialProfile();
  const [mnemonicWords, setMnemonicWords] = useState('');//记忆助记词
  const [exportKeysQrData, setExportKeysAsQrData] = useState('');
  const [shouldVisibleExportedKeys, setShouldVisibleExportedKeys] = useState(false);
  const [enteredSocialDisplayName, setEnteredSocialDisplayName] = useState(socialProfile.displayName);
  const [enteredSocialAvatar, setEnteredSocialAvatar] = useState(socialProfile?.avatar);
  const [transactionData, setTransactionData] = useState<Transactions[]>([]);; // 存储历史交易数据
  const [currentTransaction, setCurrentTransaction] = useState<Transactions | null>(null);
// 定义一个状态来存储是否还有更多数据
 const [hasMore, setHasMore] = useState(true);
  


  useEffect(() => {
    const getWhitelist = (): Promise<string[]> => {
      return new Promise((resolve, reject) => {
        storage.get(['whitelist'], async (result) => {
          try {
            const { whitelist } = result;
            setConnectedApps(whitelist ?? []);
            resolve(whitelist ?? []);
          } catch (error) {
            reject(error);
          }
        });
      });
    };

    getWhitelist();
  }, []);

  const handleCopyToClipboard = (value: string | number) => {
    const textToCopy = typeof value === 'number' ? value.toString() : value;
    navigator.clipboard.writeText(textToCopy).then(() => {
      addSnackbar('Copied!', 'success');
    });
  };

  const handleRemoveDomain = (domain: string) => {
    const newList = connectedApps.filter((app) => app.domain !== domain);
    storage.set({ whitelist: newList });
    setConnectedApps(newList);
  };

  const handleSignOutIntent = () => {
    setDecisionType('sign-out');
    setSpeedBumpMessage('Make sure you have your seed phrase backed up!');
    setShowSpeedBump(true);
  };

  const handleExportKeysIntent = () => {
    setDecisionType('export-keys');
    setSpeedBumpMessage(
      'You are about to download your private keys. Make sure you are in a safe place and no one is watching.',
    );
    setShowSpeedBump(true);
  };

  const handleExportMnemonicWords = () => {
    setDecisionType('export-mnemonic-words');
    setSpeedBumpMessage(
      'You are about to display your mnemonic words. Make sure you are in a safe place and no one is watching.',
    );
    setShowSpeedBump(true);
  };

  const handleExportKeysAsQrCodeIntent = () => {
    setDecisionType('export-keys-qr-code');
    setSpeedBumpMessage(
      'You are about to make your private keys visible in QR code format. Make sure you are in a safe place and no one is watching.',
    );
    setShowSpeedBump(true);
  };

  const handleSocialProfileSave = () => {
    storeSocialProfile({
      displayName: enteredSocialDisplayName,
      avatar: enteredSocialAvatar,
    });
    setPage('main');
  };

  const  handleTransactionDetails = (transaction: Transactions) => {
    setCurrentTransaction(transaction);
    setPage('export-transaction-details');
  };


  useEffect(() => {
    if (!socialProfile) return;
    setEnteredSocialDisplayName(socialProfile.displayName);
    setEnteredSocialAvatar(socialProfile.avatar);
  }, [socialProfile]);

  const exportKeys = async (password: string) => {
    const keys = await retrieveKeys(password);

    const keysToExport = {
      mnemonic: keys.mnemonic,
      payPk: keys.walletWif,
      payDerivationPath: keys.walletDerivationPath,
      ordPk: keys.ordWif,
      ordDerivationPath: keys.ordDerivationPath,
      identityPk: keys.identityWif,
      identityDerivationPath: keys.identityDerivationPath,
    };

    const jsonData = JSON.stringify(keysToExport, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', 'turing_wallet_keys.json');
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    URL.revokeObjectURL(url);
  };

  interface Transactions {
    tx_hash: string;
    recipient_addresses: string[];
    sender_addresses: string[];
    banlance_change: string;
    fee: string; // 根据实际数据类型可能需要调整，例如string
    utc_time: string;
  }
  // const incrementPage = () => {
  //   setTransactionPage(prevPage => prevPage + 1);
  // };
  // 这里是获取历史交易数据，并根据方向加载更多数据
const fetchTransactions = async (direction: string | undefined, clearData: boolean = false) => {
  try {
    const response = await axios.get(`https://turingwallet.xyz/v1/tbc/main/address/${tbcAddress}/history`);
    //const response = await axios.get(`http://192.168.50.61:5000/v1/tbc/testnet/address/${tbcAddress}/history`);
    const data = response.data;
    if (data && data.result.length !== 0) {
      setTransactionData(prevTransactions => {
        if (clearData) {
          return data.result; // 清除旧数据并加载新数据
        }
        if (direction === 'newer') {
          return [...data.result, ...prevTransactions]; // 加载较新的数据
        } else {
          return [...prevTransactions, ...data.result]; // 加载较旧的数据
        }
      });
    } else {
      setHasMore(false); // 如果没有更多数据，则设置hasMore为false
    }
  } catch (error) {
    addSnackbar('Error fetching transactions', 'error');
  }
};

// 在页面加载时获取交易数据
useEffect(() => {
  if (page === 'export-historic-transactions') {
    fetchTransactions('older',true);
  }
}, [page]);

  const exportMnemonicWords = async (password: string) => {
    try { 
      const keys = await retrieveKeys(password);
      if (keys && keys.mnemonic) {
        // 将助记词保存到状态中，以便显示
        setMnemonicWords(keys.mnemonic);
        // 跳转到显示助记词的页面
        setPage('export-mnemonic-words');
      } else {
        // 错误处理：未能获取到助记词
        addSnackbar('Failed to retrieve mnemonic words,The current keys may not be derived from the mnemonic.', 'error',5000);
      }
    } catch (error) {
      // 错误处理
      console.error('Error exporting mnemonic words:', error);
      addSnackbar('Error exporting mnemonic words.', 'error');
    }
  };
  
  const exportKeysAsQrCode = async (password: string) => {
    const keys = await retrieveKeys(password);
  
    const keysToExport = {
      mnemonic: keys.mnemonic,
      payPk: keys.walletWif,
      payDerivationPath: keys.walletDerivationPath,
      ordPk: keys.ordWif,
      ordDerivationPath: keys.ordDerivationPath,
    };

    const jsonData = JSON.stringify(keysToExport, null, 2);
    setExportKeysAsQrData(jsonData);

    setPage('export-keys-qr');
    setShouldVisibleExportedKeys(true);
    setTimeout(() => {
      setShouldVisibleExportedKeys(false);
    }, 10000);
  };
  const exportHistoricPage = (
    <PageWrapper $marginTop="1rem" >
      <BackButton onClick={() => setPage('main')} />
      <Text style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem',marginTop: '0' }} theme={theme} >
      Historical Transactions
    </Text>
    {/* <InfiniteScroll
      dataLength={transactionData.length} // 当前已加载的数据长度
      next={() => fetchTransactions('older')} // 加载更多数据的函数
      hasMore={hasMore} // 是否还有更多数据
      loader={<h4>Loading...</h4>} // 加载时显示的组件
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
      scrollableTarget="scrollableDiv"
    > */}
    <LockDetailsContainer >
      {transactionData.slice().map((transaction, index) => (
        <TransactionRow key={index} theme={theme} onClick={() =>  handleTransactionDetails(transaction)}>
          {/* <LockDetailsText theme={theme} onClick={() => {
    navigator.clipboard.writeText(transaction.recieveAddress).then(() => {
      addSnackbar('Copied!', 'success');
    })}}>{truncate(transaction.recieveAddress, 5, 5)}</LockDetailsText>
          <LockDetailsText theme={theme}>{transaction.valueStas}</LockDetailsText>
          <LockDetailsText theme={theme}>{transaction.feeStas}</LockDetailsText> */}
          <LockDetailsText theme={theme}  >
          {/* {truncate(transaction.tx_hash, 5, 5)} */}
          {transaction.utc_time!== 'unconfirmed' &&<>{new Date(transaction.utc_time).toLocaleDateString('en-GB', { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</>}
          {/* {transaction.utc_time!== 'unconfirmed' &&<>{transaction.utc_time}</>} */}
          </LockDetailsText>{parseFloat(transaction.banlance_change) > 0 ? (<LockDetailsText theme={theme} >Ox{truncate(transaction.sender_addresses[0], 8, 10)}</LockDetailsText>
      ) : (<LockDetailsText theme={theme} >Ox{truncate(transaction.recipient_addresses[0],8, 10)}</LockDetailsText>)}
          <LockDetailsText theme={theme} style={{ color: parseFloat(transaction.banlance_change) > 0 ? 'green' : 'red' ,fontSize: '1rem', fontWeight: 'bold'}}>
            {transaction.banlance_change}
          </LockDetailsText>
        </TransactionRow>
      ))}
      <Button theme={theme} type="secondary" label={'Go back'} onClick={() => setPage('main')} />
    </LockDetailsContainer>
      {/* <Text theme={theme}>{mnemonicWords}</Text> */}
      {/* <Button theme={theme} type="secondary" label={'Load More'} onClick= {fetchTransactions} /> */}
    </PageWrapper>
  );
  
  // const headerLockDetailsRow = (
  //   <LockDetailsContainer>
  //     <LockDetailsHeaderText style={{ textAlign: 'left' }} theme={theme}>
  //       TxId
  //     </LockDetailsHeaderText>
  //     <LockDetailsHeaderText style={{ textAlign: 'right' }} theme={theme}>
  //       Blocks Left
  //     </LockDetailsHeaderText>
  //     <LockDetailsHeaderText style={{ textAlign: 'right' }} theme={theme}>
  //       Amount
  //     </LockDetailsHeaderText>
  //   </LockDetailsContainer>
  // );

 
  const signOut = async () => {
    // console.log(typeof chrome);
    //  if (typeof chrome !== 'undefined') {console.log('未定义chrome')};
    // if (!chrome.runtime ) {console.log('runtime不存在')};
    // if (chrome.runtime ) {console.log('runtime存在')};
    await storage.clear();
    setDecisionType(undefined);
    window.location.reload();

  //   //这里是修改登出红屏
  //   chrome.runtime.sendMessage({
  //     action: 'signedOut',
  //   });
  // 
 
  };

  const handleCancel = () => {
    setShowSpeedBump(false);
  };

  useEffect(() => {
    setSelected('settings');
  }, [setSelected]);

  const handleNetworkChange = (e: any) => {
    const network = e.target.checked ? NetWork.Testnet : NetWork.Mainnet;
    updateNetwork(network);

    // The provider relies on appState in local storage to accurately return addresses. This is an easy way to handle making sure the state is always up to date.
    addSnackbar(`Switching to ${network}`, 'info');
    setTimeout(() => {
      window.location.reload();
    }, SNACKBAR_TIMEOUT - 500);

    chrome.runtime.sendMessage({
      action: 'networkChanged',
      params: {
        network,
      },
    });
  };

  const handleSpeedBumpConfirm = (password?: string) => {
    if (decisionType === 'sign-out') {
      signOut();
    }

    if (decisionType === 'export-keys' && password) {
      exportKeys(password);
      setDecisionType(undefined);
      setShowSpeedBump(false);
    }
    if (decisionType === 'export-keys-qr-code' && password) {
      exportKeysAsQrCode(password);
      setDecisionType(undefined);
      setShowSpeedBump(false);
    }
    if (decisionType === 'export-mnemonic-words' && password) {
      exportMnemonicWords(password);//这里是导出助记词的函数
      setDecisionType(undefined);
      setShowSpeedBump(false);
    }
  };

  const main = (
    <>
      {/* <SettingsRow
        name="Connected Apps"
        description="Manage the apps you are connected to"
        onClick={() => setPage('connected-apps')}
        jsxElement={<ForwardButton />}
      />
      <SettingsRow
        name="Preferences"
        description="Manage your wallet preferences"
        onClick={() => setPage('preferences')}
        jsxElement={<ForwardButton />}
      />
      <SettingsRow
        name="Testnet Mode"
        description="Applies to balances and app connections"
        jsxElement={<ToggleSwitch theme={theme} on={network === NetWork.Testnet} onChange={handleNetworkChange} />}
      /> */}
      <SettingsRow
        name="Export Keys"
        description="Download keys or Mnerm0nics words"
        onClick={() => setPage('export-keys-options')}
        jsxElement={<ForwardButton />}
      />
       <SettingsRow
        name="Historical Transactions"
        description="View the lastest transactions"
        onClick={() => setPage('export-historic-transactions')}
        jsxElement={<ForwardButton />}
      />

      <SettingsRow name="Lock Wallet" description="Immediately lock the wallet" onClick={lockWallet} />
      <SettingsRow name="Sign Out" description="Sign out of Turing Wallet completely" onClick={handleSignOutIntent} />
    </>
  );

  const connectedAppsPage = (
    <PageWrapper $marginTop={connectedApps.length === 0 ? '10rem' : '-1rem'}>
      <Show when={connectedApps.length > 0} whenFalseContent={<Text theme={theme}>No apps connected</Text>}>
        <ScrollableContainer>
          {connectedApps.map((app, idx) => {
            return (
              <ConnectedAppRow key={app.domain + idx} theme={theme}>
                <ImageAndDomain>
                  <AppIcon src={app.icon} />
                  <SettingsText theme={theme}>{app.domain}</SettingsText>
                </ImageAndDomain>
                <XIcon src={x} onClick={() => handleRemoveDomain(app.domain)} />
              </ConnectedAppRow>
            );
          })}
        </ScrollableContainer>
      </Show>
      <Button theme={theme} type="secondary" label={'Go back'} onClick={() => setPage('main')} />
    </PageWrapper>
  );

  const exportKeysAsQrCodePage = (
    <>
      <Show when={shouldVisibleExportedKeys} whenFalseContent={<Text theme={theme}>Timed out. Please try again</Text>}>
        <ExportKeysAsQrCodeContainer>
          <QrCode address={exportKeysQrData} />
        </ExportKeysAsQrCodeContainer>
      </Show>
      <Button theme={theme} type="secondary" label={'Go back'} onClick={() => setPage('main')} />
    </>
  );
  const mnemonicWordsPage = (
    <PageWrapper $marginTop="5rem">
      <BackButton onClick={() => setPage('main')} />
      <Text theme={theme}>Your Mnemonic Words:</Text>
      <CopyWrapper onClick={() => handleCopyToClipboard(mnemonicWords)}>
      <StyledCopy src={copyIcon}/>
      <Text theme={theme} style={{ margin: '0', color: theme.white }}>{mnemonicWords}</Text>
      </CopyWrapper>
      <Button theme={theme} type="secondary" label={'Go back'} onClick={() => setPage('main')} />
    </PageWrapper>
  );
  
  

  const exportKeyOptionsPage = (
    <>
      <SettingsRow
        name="Download Keys"
        description="Download your seed, private, and public keys"
        onClick={handleExportKeysIntent}
      />
      <SettingsRow
        name="Export Mnemonics words"//这里是导出助记词
        description="disaplay your  Mnemonics words"
        onClick={handleExportMnemonicWords}
      />
      {/* <SettingsRow
        name="Export Keys as QR code"
        description="Display private keys as QR code for mobile import"
        onClick={handleExportKeysAsQrCodeIntent}
      /> */}
      <Button theme={theme} type="secondary" label={'Go back'} onClick={() => setPage('main')} />
    </>
  );

  const transactionDetailsPage = (
    <PageWrapper $marginTop="1rem" style={{ overflow: 'hidden' }}>
      <BackButton onClick={() => setPage('export-historic-transactions')} />
      {currentTransaction && (
        <>
          <Text style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }} theme={theme}>
          Transaction Details
          </Text>
          <CopyWrapper onClick={() => handleCopyToClipboard(currentTransaction.tx_hash)} style={{ margin: '0' }}>
          <Text theme={theme}>TransactionID: {truncate(currentTransaction.tx_hash, 8, 8)}</Text><StyledCopy src={copyIcon} />
          </CopyWrapper>
          <CopyWrapper onClick={() => handleCopyToClipboard(currentTransaction.sender_addresses[0])} style={{ margin: '0' }}>
          <Text theme={theme}>SenderAddress: {truncate(currentTransaction.sender_addresses[0], 8, 8)}</Text><StyledCopy src={copyIcon} />
          </CopyWrapper>
          <CopyWrapper onClick={() => handleCopyToClipboard(currentTransaction.recipient_addresses[0])} style={{ margin: '0' }}>
          <Text theme={theme}>RecipientAddress: {truncate(currentTransaction.recipient_addresses[0], 8, 8)}</Text><StyledCopy src={copyIcon} />
          </CopyWrapper>
          <CopyWrapper onClick={() => handleCopyToClipboard(currentTransaction.banlance_change)} style={{ margin: '0' }}>
          <Text theme={theme}>BanlanceChange: {currentTransaction.banlance_change } </Text>
          </CopyWrapper>
          <CopyWrapper onClick={() => handleCopyToClipboard(parseFloat(currentTransaction.fee)/ 1e6)} style={{ margin: '0' }}>
          <Text theme={theme}>Fee: {parseFloat(currentTransaction.fee)} </Text>
          </CopyWrapper>
          <Text theme={theme} style={{ margin: '0' }}>Time: {currentTransaction.utc_time}</Text>
          <Button
            label="Click to view details"
            theme={theme}
            type="primary"
            onClick={() => window.open(`http://explorer.turingbitchain.io/tx/${currentTransaction.tx_hash}`, '_blank')}
          />
        </>
      )}
    </PageWrapper>
  );

  const preferencesPage = (
    <>
      <SettingsRow
        name="Social Profile"
        description="Set your display name and avatar"
        onClick={() => setPage('social-profile')}
        jsxElement={<ForwardButton />}
      />
      <SettingsRow
        name="Require Password"
        description="Require a password for sending assets?"
        jsxElement={
          <ToggleSwitch
            theme={theme}
            on={isPasswordRequired}
            onChange={() => updatePasswordRequirement(!isPasswordRequired)}
          />
        }
      />
      <SettingsRow
        name="Auto Approve Limit"
        description="Transactions at or below this TBC amount will be auto approved."
        jsxElement={
          <Input
            theme={theme}
            placeholder={String(noApprovalLimit)}
            type="number"
            onChange={(e) => updateNoApprovalLimit(Number(e.target.value))}
            value={noApprovalLimit}
            style={{ width: '5rem', margin: 0 }}
          />
        }
      />
      <Button theme={theme} type="secondary" label={'Go back'} onClick={() => setPage('main')} />
    </>
  );

  const socialProfilePage = (
    <PageWrapper $marginTop="5rem">
      <BackButton onClick={() => setPage('preferences')} />
      <SettingsText theme={theme}>Display Name</SettingsText>
      <Input
        theme={theme}
        placeholder="Display Name"
        type="text"
        onChange={(e) => setEnteredSocialDisplayName(e.target.value)}
        value={enteredSocialDisplayName}
      />
      <SettingsText theme={theme}>Avatar</SettingsText>
      <Input
        theme={theme}
        placeholder="Avatar Url"
        type="text"
        onChange={(e) => setEnteredSocialAvatar(e.target.value)}
        value={enteredSocialAvatar}
      />
      <Button
        theme={theme}
        type="primary"
        label="Save"
        style={{ marginTop: '1rem' }}
        onClick={handleSocialProfileSave}
      />
    </PageWrapper>
  );

  return (
    <Show
      when={!showSpeedBump}
      whenFalseContent={
        <SpeedBump
          theme={theme}
          message={speedBumpMessage}
          onCancel={handleCancel}
          onConfirm={(password?: string) => handleSpeedBumpConfirm(password)}
          showSpeedBump={showSpeedBump}
          withPassword={decisionType === 'export-keys' || decisionType === 'export-keys-qr-code'||decisionType === 'export-mnemonic-words'}
        />
      }
    >
      <Content>
        <TopNav />
        <Show when={page === 'main'}>{main}</Show>
        <Show when={page === 'connected-apps'}>{connectedAppsPage}</Show>
        <Show when={page === 'preferences'}>{preferencesPage}</Show>
        <Show when={page === 'social-profile'}>{socialProfilePage}</Show>
        <Show when={page === 'export-keys-options'}>{exportKeyOptionsPage}</Show>
        <Show when={page === 'export-historic-transactions'}>{exportHistoricPage}</Show> 
        <Show when={page === 'export-keys-qr'}>{exportKeysAsQrCodePage}</Show>
        <Show when={page === 'export-mnemonic-words'}>{mnemonicWordsPage}</Show>
        <Show when={page === 'export-transaction-details'}>{transactionDetailsPage}</Show>
      </Content>
    </Show>
  );
};

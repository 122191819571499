import { validate } from 'bitcoin-address-validation';
import React, { useEffect, useState } from 'react';
import { useKeys } from '../hooks/useKeys';
// import { TuringsLogo } from '../components/Reusable';
import styled from 'styled-components';
import tbcCoin from '../assets/TuringLiitleCoin.png';
import turingsLogo from '../assets/TuringHome.png';
import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { PageLoader } from '../components/PageLoader';
import { SpeedBump } from '../components/SpeedBump';
import { QrCode } from '../components/QrCode';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // 引入轮播图样式
import turingTownLogo1 from '../assets/turing-town-1.jpg'; // 示例图片
import {
  ButtonContainer,
  ConfirmContent,
  FormContainer,
  HeaderText,
  MainContent,
  ReceiveContent,
  Text,
  Warning,
} from '../components/Reusable';
import { Show } from '../components/Show';
import { TopNav } from '../components/TopNav';
import { useBottomMenu } from '../hooks/useBottomMenu';
import { useTbc } from '../hooks/useTbc';
import { useSnackbar } from '../hooks/useSnackbar';
import { useSocialProfile } from '../hooks/useSocialProfile';
import { useTheme } from '../hooks/useTheme';
import { useWeb3Context } from '../hooks/useWeb3Context';
import { ColorThemeProps } from '../theme';
import { TBC_DECIMAL_CONVERSION, HOSTED_PANDA_IMAGE, DUST, GAME_RECEIVE_ADDRESS } from '../utils/constants';
import { formatUSD } from '../utils/format';
import { sleep } from '../utils/sleep';
import { storage } from '../utils/storage';
import copyIcon from '../assets/copy.svg';
import { AssetRow } from '../components/AssetRow';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(75%);
  overflow-y: auto;
  overflow-x: hidden;
`;
//12
const TuringsLogo = styled.img`
  width: 240px;
  height: 143px;
`;

const MiddleContainer = styled.div<ColorThemeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 2.75rem 1rem;
`;

const ProfileImage = styled.img`
  width: 4rem;
  height: 4rem;
  margin: 0.25rem;
  border-radius: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.2);
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center; // 垂直居中
  justify-content: start; // 从头开始排列
`;

const BalanceContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img<{ size?: string }>`
  width: ${(props) => props.size ?? '1.5rem'};
  height: ${(props) => props.size ?? '1.5rem'};
  margin: 0 1rem 0 0;
`;

const InputAmountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const CopyAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 2rem 0;
`;

const StyledCopy = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
`;

type PageState = 'main' | 'receive' | 'send'|'assemble';
type AmountType = 'tbc' | 'usd';

export type TbcWalletProps = {
  isOrdRequest: boolean;
};

// 创建一个新的组件来控制轮播图的尺寸
const StyledCarouselContainer = styled.div`
  width: 350px;  // 固定宽度为800像素
  height: 100px; // 固定高度为600像素
  margin: 0 auto; // 居中对齐
`;

export const TbcWallet = (props: TbcWalletProps) => {
  const { isOrdRequest } = props;
  const { theme } = useTheme();
  const { setSelected } = useBottomMenu();
  const [pageState, setPageState] = useState<PageState>('main');
  const [showSpeedBump, setShowSpeedBump] = useState(false);
  const [decisionType, setDecisionType] = useState<DecisionType | undefined>();
  const [speedBumpMessage, setSpeedBumpMessage] = useState('');
  const [satSendAmount, setSatSendAmount] = useState<number | null>(null);
  const [usdSendAmount, setUsdSendAmount] = useState<number | null>(null);
  const [receiveAddress, setReceiveAddress] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [amountType, setAmountType] = useState<AmountType>('tbc');
  const [successTxId, setSuccessTxId] = useState('');
  const [screenSwitchCount ,setscreenSwitchCount] = useState(0);
  const [assembledTransaction, setAssembledTransaction] = useState<SendTbcResponse | null>(null);
  const { addSnackbar, message } = useSnackbar();
  const { socialProfile } = useSocialProfile();
  const { isPasswordRequired } = useWeb3Context();
  const { verifyPassword } = useKeys();
  const location = useLocation(); // 用于获取当前页面的URL
  const { tbcAddress, tbcBalance, isProcessing, setIsProcessing, assembleTbc,sendtbc, updateTbcBalance, exchangeRate } = useTbc();

  type SendTbcResponse = {
    txid?: string;
    rawtx?: string;
    amount?: number;
    change?: number;
    fee?: number;
    error?: string;
  };

  type DecisionType = 'password-confirm';
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setscreenSwitchCount((Count) => Count + 1); // 刷新余额
        //console.log(tbcBalance);
        //updateTbcBalance(true);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange); // 清除事件监听器
    };
  }, []); // 仅在组件初次挂载时调用

  useEffect(() => {
    // // 获取URL中的flag参数
    // const params = new URLSearchParams(location.search);
    // const flagValue = params.get('charge/town') === 'true' ? true : false;// 如果flag为true，设置为true
    // // 获取URL的路径部分
    const pathname = window.location.href;
    console.log("pathname",pathname);
    // 检查路径中是否包含 "charge/town"
    const flagValue = pathname.includes('charge/town') ? true : false;
    //const flagValue = pathname.includes('tbc-wallet') ? true : false;

    console.log("flagValue",flagValue);
    // 根据flag决定是否自动填充收款地址
    if (flagValue) {
      setPageState('send');
      setReceiveAddress(GAME_RECEIVE_ADDRESS); // 自动填充收款地址
    }
    //  else {
    //   setReceiveAddress(''); // 清空收款地址
    // }
  }, [location]);

  useEffect(() => {
    if(screenSwitchCount > 0){
    nukeUtxos();
    }
  }, [screenSwitchCount]); // 仅在组件初次挂载时调用

  useEffect(() => {
    if (isOrdRequest) {
      setSelected('ords');
    } else {
      setSelected('tbc');
    }
  }, [isOrdRequest, setSelected]);

  useEffect(() => {
    if (!successTxId) return;
    if (!message && tbcAddress) {
      setPageState('main')
      resetSendState();
      //setPageState('main');
    }
  }, [successTxId, message, tbcAddress]);

  const resetSendState = () => {
    // storage.remove('paymentUtxos');
    // // Give enough time for storage to remove
    // setTimeout(() => {
    //   updateTbcBalance(true);//这里是自动更新余额
    // }, 500);
    nukeUtxos();
    setPasswordConfirm('');
    setSatSendAmount(null);
    //setUsdSendAmount(null);
    //setAmountType('tbc');
    setReceiveAddress('');
    setSuccessTxId('');
    setIsProcessing(false);

    //  setTimeout(() => {//这里是零确认修改的地方
    //   //nukeUtxos();
    //   updateTbcBalance(true);
    //   }, 500);
      
    // console.log('进行的本地更新');
  };


  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(tbcAddress).then(() => {
      addSnackbar('Copied!', 'success');
    });
  };

  const toggleAmountType = () => {
    if (amountType === 'tbc') {
      setAmountType('usd');
    } else {
      setAmountType('tbc');
    }
    setUsdSendAmount(null);
    setSatSendAmount(null);
  };

  const handleSpeedBumpConfirm = async (password?:string) => {
    // if (decisionType === 'password-confirm' && password && assembledTransaction?.rawtx) {
     if (assembledTransaction?.rawtx) {
      //setDecisionType(undefined);
      setShowSpeedBump(false);
      // const sendRes = await sendtbc(assembledTransaction.rawtx, password); // 使用弹窗输入的密码和保存的 rawtx
      const sendRes = await sendtbc(assembledTransaction.rawtx); // 使用弹窗输入的密码和保存的 rawtx
      // setDecisionType(undefined);
      // setShowSpeedBump(false);
      //setIsProcessing(true);
      //await sleep(15);
      if (!sendRes.txid) {
        addSnackbar('An error occurred while sending TBC. Please try again.', 'error');
        setIsProcessing(false);
        return;
      }
      //setIsProcessing(false);
      setSuccessTxId(sendRes.txid);
      addSnackbar('Transaction Successful! Please wait a few minutes for your balance to refresh.', 'success');
    }
  };
  const handleCancel = () => {
    setIsProcessing(false);
    setPageState('assemble');
    storage.remove('paymentUtxos');
    // Give enough time for storage to remove
    setTimeout(() => {
      updateTbcBalance(true);//这里是自动更新余额
    }, 50);
    setShowSpeedBump(false);
  };

  const handleSendTbc = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);
    if (!validate(receiveAddress)) {
      addSnackbar('You must enter a valid TBC address. Paymail not yet supported.', 'info');
      setIsProcessing(false);
      return;
    }

    if (!satSendAmount && !usdSendAmount) {
      addSnackbar('You must enter an amount.', 'info');
      setIsProcessing(false);
      return;
    }

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar('You must enter a password!', 'error');
      setIsProcessing(false);
      return;
    }
    
    const isVerified = await verifyPassword(passwordConfirm);//这里是等待确认密码的地方
    if (!isVerified) {
      addSnackbar('Invalid Password!', 'error');
      setIsProcessing(false);
      return;
    } 
  

    let satoshis = satSendAmount ?? 0;
    if (satoshis < DUST) {
      addSnackbar(`output is below dust threshold：${DUST}`, 'error');
      setPasswordConfirm('');
      setIsProcessing(false);
      return;
    }
    if (amountType === 'usd' && usdSendAmount) {
      satoshis = Math.ceil((usdSendAmount / exchangeRate) * TBC_DECIMAL_CONVERSION);
    }

    const assembleRes = await assembleTbc([{ address: receiveAddress, satoshis }], passwordConfirm);
    //const sendRes = await sendTbc([{ address: receiveAddress, satoshis }], passwordConfirm);
     if ( assembleRes.rawtx==null||assembleRes.error) {
    // if (!sendRes.txid ) {
      const message =
      assembleRes.error === 'invalid-password'
          ? 'Invalid Password!'
          : assembleRes.error === 'insufficient-funds'
            ? 'Insufficient Funds ! (including transaction fees)'
            : assembleRes.error === 'fee-too-high'
              ? 'Miner fee too high!'
              : assembleRes.error === 'output is below DUST'
              ? ` output is below dust threshold：${DUST}`
               : 'An unknown error has occurred! Try again.';//这里是等待确认错误的地方"output is below DUST"
      nukeUtxos();
      addSnackbar(message, 'error');
      setPasswordConfirm('');
      return;

    }
    setAssembledTransaction(assembleRes);  // 将 assembleRes 保存到状态中
    setPageState('send');
    //setDecisionType('password-confirm');
    setSpeedBumpMessage(`Check Transaction Details:\nAddress: ${receiveAddress}\nAmount: ${assembleRes.amount ? assembleRes.amount / 1e6 : 0}TBC\nChange: ${assembleRes.change ? assembleRes.change / 1e6 : 0}TBC\nFee: ${assembleRes.fee}sats`);
    setShowSpeedBump(true);
  
    // const sendRes = await sendtbc(assembleRes.rawtx, passwordConfirm);

    // if (!sendRes.txid) {
    //   addSnackbar('An error occurred while sending TBC. Please try again.', 'error');
    //   return;
    // }
    // setSuccessTxId(sendRes.txid);
    // addSnackbar('Transaction Successful! Please wait a few minutes for your balance to refresh.', 'success');
    // setSuccessTxId(sendRes.txid);//这里是本地成功的txid
    // addSnackbar('Transaction Successful! Please wait a few minutes for your balance to refresh.', 'success');
//     const feeStas = 80; // 示例交易费用，可以根据实际情况调整
//     const remainBalance = tbcBalance - satoshis - feeStas;
//     const time = new Date().toISOString().slice(0, 19).replace('T', ' '); // 获取UTC时间
//     const historyData = {
//       txid: sendRes.txid,
//       recieveAddress: receiveAddress,
//       valueStas: satoshis,
//       feeStas,
//       remainBalance,
//       time,
//     };

//     try {
//       const response = await axios.post(`https://turingwallet.xyz/v1/tbc/address/${tbcAddress}/add/send/history/`, historyData);
//       if (response.data.status === 0) {
//        console.log('Transaction history submitted successfully!', 'success');
//       } else {
//        console.log('Failed to submit transaction history.', 'error');
//       }
//     } catch (error) {
//        console.log('An error occurred while submitting transaction history.', 'error');
//     }
 };

  

  const fillInputWithAllTbc = () => {
    setSatSendAmount(Math.round(tbcBalance * TBC_DECIMAL_CONVERSION));
  };

  useEffect(() => {
    const calcValue = () => {
      return amountType === 'tbc'
        ? satSendAmount
          ? satSendAmount / TBC_DECIMAL_CONVERSION
          : undefined
        : amountType === 'usd'
          ? usdSendAmount
            ? usdSendAmount
            : undefined
          : undefined;
    };

    calcValue();
  }, [satSendAmount, usdSendAmount, amountType]);

  const getLabel = () => {
    return amountType === 'tbc' && satSendAmount
      ? `Send ${(satSendAmount / TBC_DECIMAL_CONVERSION).toFixed(6)}`
      : amountType === 'usd' && usdSendAmount
        ? `Send ${formatUSD(usdSendAmount)}`
        : 'Enter Send Details';
  };

  // const nukeUtxos = () => {
  //   storage.remove('paymentUtxos');
  //   // Give enough time for storage to remove
  //   setTimeout(() => {
  //     updateTbcBalance(true);//这里是自动更新余额
  //   }, 50);
  //   addSnackbar('Balance refreshed successfully.', 'success');
  // };
  
  const nukeUtxos = async () => {
    try {
      // 移除storage中的数据
      await storage.remove('paymentUtxos');
      // 定义一个函数用于重试更新余额
      const retryUpdateTbcBalance = async (retries = 3) => {
        while (retries > 0) {
          try {
            await updateTbcBalance(true); // 尝试更新余额
           // addSnackbar('Balance refreshed successfully.', 'success'); // 成功后显示消息
            return; // 如果成功，直接返回
          } catch (error) {
            retries -= 1; // 如果失败，减少重试次数
            if (retries === 0) throw error; // 如果没有剩余重试次数，抛出错误
          }
        }
      };
  
      // 尝试更新余额
      await retryUpdateTbcBalance();
  
    } catch (error) {
      addSnackbar('Failed to refresh balance. Please try again.', 'error');
      console.error('Error updating balance:', error);
    }
  };
  

  const receive = (
    <ReceiveContent>
      <HeaderText style={{ marginTop: '1rem' }} theme={theme}>
        Receive TBC
      </HeaderText>
      <Text style={{ marginBottom: '1.25rem' }} theme={theme}>
        Only send TBC to this address. 
        {/* <Warning theme={theme}>Do not send Ordinals or TBC20 here.</Warning> */}
      </Text>
      <QrCode address={tbcAddress} onClick={handleCopyToClipboard} />
      <CopyAddressWrapper onClick={handleCopyToClipboard}>
        <StyledCopy src={copyIcon} />
        <Text theme={theme} style={{ margin: '0', color: theme.white, fontSize: '0.75rem' }}>
          {tbcAddress}
        </Text>
      </CopyAddressWrapper>
      <Button
        label="Go back"
        theme={theme}
        type="secondary"
        onClick={() => {
          setPageState('main');
          updateTbcBalance(true);
        }}
      />
    </ReceiveContent>
  );

  const handleCarouselClick = (url: string) => {
    window.open(url, '_blank'); // 点击时跳转到新标签页
  };

const turingTownUrl = "https://game.turingtown.org/index.html?tbcAddress=" + tbcAddress;

const main = (
  <MainContent>
    <MiddleContainer theme={theme}>
      <Show when={socialProfile.avatar !== HOSTED_PANDA_IMAGE}>
        <ProfileImage title="Refresh balance" src={socialProfile.avatar} />
      </Show>
      <TuringsLogo src={turingsLogo} style={{ cursor: 'pointer' }} title="BlanceFresh" onClick={nukeUtxos} />

      <ButtonContainer>
        <Button theme={theme} type="primary" label="Receive" onClick={() => setPageState('receive')} />
        <Button theme={theme} type="primary" label="Send" onClick={() => { nukeUtxos(); setPageState('assemble'); }} />
      </ButtonContainer>
      <AssetRow balance={tbcBalance} icon={tbcCoin} ticker="TBC" usdBalance={tbcBalance * exchangeRate} />

      {/* 改为展示静态图片
      <div onClick={() => handleCarouselClick(turingTownUrl)} style={{ cursor: 'pointer', marginTop: '20px', textAlign: 'center' }}>
        <img src={turingTownLogo1} alt="Turing Town" style={{ maxWidth: '97%', height: 'auto' }} />
        <p className="legend" style={{ fontSize: '14px', marginTop: '10px' }}>Play now!</p>
      </div> */}
    </MiddleContainer>
  </MainContent>
);

  const send = (
    <>
      <ConfirmContent>
        <HeaderText theme={theme}>Send TBC</HeaderText>
        <Text
          theme={theme}
          style={{ cursor: 'pointer' }}
          onClick={fillInputWithAllTbc}
        >{`Balance: ${tbcBalance}`}</Text>
        <FormContainer noValidate onSubmit={(e) => handleSendTbc(e)}>
          <Input
            theme={theme}
            placeholder="Enter Address"
            type="text"
            onChange={(e) => setReceiveAddress(e.target.value)}
            value={receiveAddress}
          />
          <InputAmountWrapper>
            <Input
              theme={theme}
              placeholder={amountType === 'tbc' ? 'Enter TBC Amount' : 'Enter USD Amount'}
              type="number"
              step="0.00000001"
              value={
                satSendAmount !== null && satSendAmount !== undefined
                  ? satSendAmount / TBC_DECIMAL_CONVERSION
                  : usdSendAmount !== null && usdSendAmount !== undefined
                    ? usdSendAmount
                    : ''
              }
              onChange={(e) => {
                const inputValue = e.target.value;

                // Check if the input is empty and if so, set the state to null
                if (inputValue === '') {
                  setSatSendAmount(null);
                  setUsdSendAmount(null);
                } else {
                  // Existing logic for setting state
                  if (amountType === 'tbc') {
                    setSatSendAmount(Math.round(Number(inputValue) * TBC_DECIMAL_CONVERSION));
                  } else {
                    setUsdSendAmount(Number(inputValue));
                  }
                }
              }}
            />
            {/* <Icon
              src={switchAsset}
              size="1rem"
              style={{
                position: 'absolute',
                right: '2.25rem',
                cursor: 'pointer',
              }}
              onClick={toggleAmountType}
            /> */}
          </InputAmountWrapper>
          <Show when={isPasswordRequired}>
            <Input
              theme={theme}
              placeholder="Enter Wallet Password"
              type="password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </Show>
          <Button
            theme={theme}
            type="primary"
            label={getLabel()}
            disabled={isProcessing || (!usdSendAmount && !satSendAmount)}
            isSubmit
          />
        </FormContainer>
        <Button
          label="Go back"
          theme={theme}
          type="secondary"
          onClick={() => {
            setPageState('main');
            resetSendState();
          }}
        />
      </ConfirmContent>
    </>
  );
  //这里是修改回插件
  return (
    <Show
    when={!showSpeedBump}
    whenFalseContent={
      <div style={{whiteSpace:'pre-wrap'}}>
      <SpeedBump
        theme={theme}
        message={speedBumpMessage}
        onCancel={handleCancel}
        onConfirm={(password?: string) => handleSpeedBumpConfirm(password)}
        showSpeedBump={showSpeedBump}
        withPassword={decisionType === 'password-confirm' }
      />
      </div>
    }
  >
    <Content>
      <TopNav />
      <Show when={isProcessing && pageState === 'main'}>
        <PageLoader theme={theme} message="Loading wallet..." />
      </Show>
      <Show when={isProcessing && pageState === 'assemble'}>
        <PageLoader theme={theme} message="Assembling transaction..." />
      </Show>
      <Show when={isProcessing && pageState === 'send'}>
        <PageLoader theme={theme} message="Sending TBC..." />
      </Show>
      <Show when={!isProcessing && pageState === 'main'}>{main}</Show>
      <Show when={!isProcessing && pageState === 'receive'}>{receive}</Show>
      <Show when={!isProcessing && pageState === 'assemble'}>{send}</Show>
      <Show when={!isProcessing && pageState === 'send'}>{send}</Show>
    </Content>
    </Show>
  );
};
